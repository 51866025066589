import React, { Fragment } from 'react';
import { injectIntl, InjectedIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
  AppState,
  hasAllPlayersSelected,
  selectNumberOfPlayersSelected,
  updateCreativityProp,
  Status,
} from '../../../../redux/modules';
import { ScreenSelection } from '../../../../components';
import {
  Section,
  SectionLine,
  CheckboxCustom,
  CustomContainerButton,
  H1Custom,
  H4Custom,
  RightSectionLink,
  CustomButtonLarge,
} from '../styles';

interface IProps {
  hasAllPlayersSelected?: boolean;
  id: string;
  intl?: InjectedIntl;
  numberOfPlayersSelected?: number;
  updateCreativityProp?: any;
  status: Status;
}

interface IState {
  activeModal?: boolean;
}

class LocationSelector extends React.Component<IProps, IState> {
  state: IState = {};

  handleSelectAll = (e: CheckboxChangeEvent) => {
    this.props.updateCreativityProp(this.props.id, {
      allPlayers: e.target.checked,
    });
  };

  handleCustomCancelModal = () => {
    this.setState({ activeModal: false });
  };

  handleCustomDone = () => {
    this.setState({ activeModal: false });
  };

  render() {
    const {
      id,
      intl,
      hasAllPlayersSelected,
      numberOfPlayersSelected,
      status,
    } = this.props;

    return (
      <Fragment>
        <Section>
          <SectionLine>
            <H1Custom>
              <FormattedMessage id="editor.info.locations.title" />
            </H1Custom>
            <H4Custom>
              <FormattedMessage
                id="editor.info.locations.screenTitle"
                values={{
                  number: hasAllPlayersSelected
                    ? this.props.intl!.formatMessage({ id: 'All' })
                    : numberOfPlayersSelected
                      ? numberOfPlayersSelected!.toString()
                      : '0',
                }}
              />
            </H4Custom>
            <RightSectionLink />
          </SectionLine>
          <SectionLine>
            <CheckboxCustom
              checked={hasAllPlayersSelected}
              disabled={
                status === Status.PUBLISHED ||
                status === Status.PENDING_VALIDATION
              }
              onChange={this.handleSelectAll}
            >
              <FormattedMessage id="editor.info.locations.allLocationsTitle" />
            </CheckboxCustom>
            <CustomContainerButton>
              <CustomButtonLarge
                disabled={
                  hasAllPlayersSelected ||
                  status === Status.PUBLISHED ||
                  status === Status.PENDING_VALIDATION
                }
                onClick={() => {
                  this.setState({ activeModal: true });
                }}
                type="grey"
                button={intl!.formatMessage({
                  id: 'editor.info.screen.buttonSelectScreen',
                })}
              />
            </CustomContainerButton>
          </SectionLine>
        </Section>
        <ScreenSelection
          id={id}
          visible={!!this.state.activeModal}
          handleCancel={this.handleCustomCancelModal}
          handleDone={this.handleCustomDone}
        />
      </Fragment>
    );
  }
}

export default connect(
  (state: AppState, props: IProps) => ({
    hasAllPlayersSelected: hasAllPlayersSelected(state, props.id),
    numberOfPlayersSelected: selectNumberOfPlayersSelected(state, props.id),
  }),
  {
    updateCreativityProp,
  }
)(injectIntl<any>(LocationSelector));
