export const stockholm = require('./stockholm.png');
export const nice = require('./nice.jpg');
export const caen = require('./CCF.jpg');
export const Clock = require('./clock.svg');
export const caenlamer = require('./Caen-La-Mer.jpg');
export const ifs = require('./IFS.jpg');
export const siege = require('./Siege.jpg');
export const idfnord = require('./IDFNord.jpg');
export const grandouest = require('./GrandOuest.jpg');
export const grandest = require('./GrandEst.jpg');
export const sudouest = require('./SudOuest.jpg');
export const mediterranee = require('./Mediterranee.jpg');
