import React from 'react';
import { Video } from '../styles';
import { Element } from '../Element';
import { LoadingMessage } from '../../.././../../../../components/index';
import { FormattedMessage } from 'react-intl';

interface ElementExtraProps {
  url: string;
  sources?: { url: string; type: string }[];
}

interface ElementExtraState {
  playing: boolean;
  url?: string;
  sources?: { url: string; type: string }[];
}

export default class VideoElement extends Element<
  ElementExtraProps,
  ElementExtraState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      playing: false,
      url: props.element.url,
      sources: props.element.sources,
    };
  }

  video: any;

  render() {
    const { url, sources } = this.state;
    const styles = this.getStyle();

    if (!url) {
      return <div />;
    }

    if (!sources) {
      return (
        <LoadingMessage>
          <FormattedMessage id="loading.message" />
        </LoadingMessage>
      );
    }

    return (
      <Video>
        <video
          ref={node => (this.video = node)}
          loop={true}
          width={styles.width}
          height={styles.height}
        >
          {sources &&
            sources.map((source: any, index: number) => (
              <source key={index} src={source.url} type={source.type} />
            ))}
        </video>
      </Video>
    );
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.element.url !== this.props.element.url) {
      this.setState({ url: undefined, sources: undefined });
    }
  }

  componentDidUpdate() {
    if (!this.state.url && this.props.element.url) {
      this.setState({
        url: this.props.element.url,
        sources: this.props.element.sources,
      });
    }
  }

  togglePlay = () => {
    const { video } = this;

    if (video.paused) {
      video.play();
      this.setState({ playing: true });
    } else {
      video.pause();
      this.setState({ playing: false });
    }
  };
}
