import styled from 'styled-components';

export const Text = styled.div`
  background-color: transparent;
  border: none;
  display: flex;
  resize: none;
  line-height: normal;
  padding: 0;
  user-select: none;
  word-break: normal;
`;

export const Img = styled.img`
  display: block;
  -webkit-user-drag: none;
`;

export const Video = styled.div`
  display: block;
  -webkit-user-drag: none;
`;
