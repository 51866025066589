import URI from 'urijs';
import { getAccessToken } from '../auth';
const {
  REACT_APP_PLAYER,
  REACT_APP_THUMB_SERVER,
  REACT_APP_THUMB_DOMAIN,
  REACT_APP_THUMB_COLLECTION,
} = process.env;
export const api = process.env.REACT_APP_API;
export const localStorageApp = 'cityportal-app';
export const localStorageLang = 'cityportal-app-lang';
export const getToken = () => localStorage.getItem(localStorageApp);
export const setToken = (token: string) =>
  localStorage.setItem(localStorageApp, token);
export const setLang = (lang: string) =>
  localStorage.setItem(localStorageLang, lang);
export const getLang = () => {
  const defaultUserLang = 'en';
  const languages = ['es', 'en', 'de', 'fr'];
  if (languages.includes(defaultUserLang)) {
    setLang(defaultUserLang);
    return defaultUserLang;
  } else {
    return localStorage.getItem(localStorageLang);
  }
};

const getHeaders = (contentType = 'application/json') => {
  const token = getAccessToken();
  const h: any = {};
  if (contentType) {
    h['Content-Type'] = contentType;
  }
  h.Accept = 'application/json';
  h.Authorization = `Bearer ${token}`;
  return h;
};

export const request = async (
  path: string,
  options = {},
  requestType = 'application/json',
  responseType = 'json'
) => {
  const url = `${api}/${path}`;
  const settings = {
    headers: getHeaders(requestType),
    responseType,
    ...options,
  };
  return fetch(url, settings);
};

export const ELEMENTS = {
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
  WEATHER: 'weather',
  QR: 'qrcode',
  LOCATION: 'location',
};

export const getPlayerUrl = (creativityId: string) =>
  `${REACT_APP_PLAYER}/${creativityId}?autoplay&lang=fr`;

export const getThumbnailUrl = (creativityId: string) =>
  `${REACT_APP_THUMB_SERVER}/?creative=${creativityId}&domain=${REACT_APP_THUMB_DOMAIN}&collection=${REACT_APP_THUMB_COLLECTION}`;

interface IPlayer {
  bsPlayerId: string;
}

export const fetchGeolocation = async (
  address: string,
  feature?: string,
  player?: IPlayer
) => {
  const options = {
    method: 'GET',
    headers: getHeaders(),
  };
  let url = URI(`${api}/geo/location`);

  if (player && player.bsPlayerId) {
    url = URI(`${api}/geo/location/${player.bsPlayerId}`);
  }

  if (address) {
    url.addSearch('address', address);
  }

  if (feature) {
    url.addSearch('feature', feature);
  }

  return fetch(url.toString(), options).then(response => response.json());
};

export const merge = (a: any[], b: any[], prop: any) => {
  var reduced = a.filter(
    aitem => !b.find(bitem => aitem[prop] === bitem[prop])
  );
  return reduced.concat(b);
};

export const checkStatus = (response: Response) => {
  if (response.ok) {
    return response;
  } else {
    let error = new Error(response.statusText);
    throw error;
  }
};

export const toJSON = (response: Response) => response.json();

export { getHeaders };
