import React, { Component, createRef } from 'react';
import { uid } from 'react-uid';

import { Container } from './styles';

import Element from './Element';
import { IFormat, ISlide } from '../../../../redux/modules';

interface IProps {
  format: IFormat;
  zoom: number;
  slide: ISlide;
  activeElement: number;
}

export default class Slide extends Component<IProps> {
  getStyle() {
    const { format, zoom } = this.props;

    return {
      cursor: 'default',
      height: ((format && format.height) || 0) * zoom,
      width: ((format && format.width) || 0) * zoom,
    };
  }

  slide: React.RefObject<any> = createRef();

  render() {
    const { slide, zoom, activeElement } = this.props;

    return (
      <Container ref={this.slide} style={this.getStyle()}>
        {slide &&
          slide.elements.map((element, i) => (
            <Element
              key={uid(element)}
              element={element}
              index={i}
              activeElement={activeElement}
              zoom={zoom}
            />
          ))}
      </Container>
    );
  }
}
